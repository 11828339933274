.claim-todo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  .loader {
    margin-top: -5px;
    margin-left: 8px;
    opacity: 0;
    transition: 0.1s all ease-in-out;
    flex-grow: 1;
  }

  .invoice-dropzone {
    border-color: #ccc;
  }
}

.claim-todo.complete {
  color: #ccc;

  .checkbox-container {
    color: #ccc;

    svg .square {
      stroke: #ccc;
      fill: #ccc;
    }
  }

  .loader {
    opacity: 1;
  }

  .date-picker {
    pointer-events: none;
    opacity: 0.5;
  }

  &.confirmed {
    pointer-events: none;

    .loader {
      opacity: 0;
    }

    .checkbox-container {
      text-decoration: line-through;
    }
  }
}

.claim-todo.file {
  text-decoration: none;
  color: #aaa;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .checkbox-container {
    opacity: 0.5;
    pointer-events: none;
  }

  .todo-drop {
    border-color: #aaa;
    display: block;
    width: 100%;
    min-width: 100%;
    flex-grow: 1;
    margin: 10px auto;
  }

  svg .square {
    stroke: #ccc;
  }

  &.complete {
    .todo-drop {
      display: none;
    }
  }

  &.has-file {
    color: #333;
    opacity: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .checkbox-container {
      pointer-events: all;
      opacity: 1;
    }

    svg .square {
      stroke: #aaa;
    }
  }
}

.claim-todo.disabled {
  pointer-events: none;
  text-decoration: none;
  color: #ccc;
  opacity: 0.5;

  .todo-drop {
    display: none;
    opacity: 1;
    border-color: #ccc;
  }

  svg .square {
    stroke: #ccc;
  }
}

.claim-todo.outgoing {
  color: #aaa;

  .checkbox-container {
    color: #aaa;

    svg .square {
      stroke: #ccc;
      fill: #ccc;
    }
  }

  .date-picker {
    pointer-events: none;
    opacity: 0.5;
  }
}

.react-datepicker__input-container > input {
  height: 26px;
  border: 1px solid #ccc;
  padding-left: 5px;
}

.form-group {
  margin: 0 !important;
}

.input-group-text {
  font-size: 1em;
  background: #ebebeb !important;
  border: 1px solid #ccc !important;
  border-radius: 0;
}

.table-data {
  input {
    border-radius: 0 !important;
    max-height: 26px;
  }

  .input-group-text {
    font-size: 1em;
    max-height: 26px;
    background: #ebebeb !important;
    border: 1px solid #ccc !important;
    border-radius: 0;
  }

  button {
    max-height: 26px;
    height: 26px;
    line-height: 26px;
    padding: 5px 7px !important;
    font-size: 12px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.table-data .form-group {
  width: 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
