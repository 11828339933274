/* NavBar Styles */
.navbar {
  padding: 15px 30px 15px 15px;
  z-index: 1050;
  background: #424484;
  max-height: 87px;
  height: 87px;
  background: #3d42f3;
}

.navbar > * {
  color: #fff;
  font-weight: bold;
}

.navbar a {
  color: #fff;
}

.navbar a:hover {
  text-decoration: none;
}

li.nav-item {
  display: flex;
  align-items: center;
}

.back-brand-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 87px;
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.sidebar-bottom {
  position: absolute;
  bottom: 3vh;
  width: 100%;
  padding: 10px 25px;
  text-align: center;
}

.signout-button {
  color: #fff;
  background: transparent;
  font-weight: bold;
  font-size: 1em !important;
  padding: 1em 1.5em !important;
  border-radius: 10px !important;
  opacity: 0.6;
  text-decoration: none !important;
  transition: 0.15s all ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.signout-button:hover, .signout-button:active, .signout-button:focus {
  opacity: 1;
  background: white;
  color: #3d42f3;
}

.navbar-fixed-top {
  position:fixed;
}

.navbar-brand {
  font-size: 20pt;
  font-style: italic;
  border: 1px solid transparent;
  transition: 0.2s all ease-in-out; 
  padding-left: 25px;
  padding-right: 25px;
  border: 2px solid transparent;
  opacity: 0.9;
}

.sub-brand {
  position: absolute;
  bottom: 0;
  font-size: 0.5em;
  transform: translateX(-100%) translateY(7px);
}

.navbar-brand.with-backbutton {
  padding-left: 0;
  margin-top: -15px;
  line-height: 1.2em;
}

.navbar-brand:hover {
  opacity: 1;
  
}

li.nav-item > a {
  color: #fff;
  opacity: 0.9;
  font-size: 0.8em;
  padding: 10px 15px;
  transition: 0.2s all ease-in-out;
  border: 2px solid transparent;
}

li.nav-item > a:hover {
  opacity: 1;
  border-bottom: 2px solid #1329a2;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 8px;
}

img.user-picture {
  height: 35px;
  width: 35px;
  margin: 0;
  padding: 0;
  border-radius: 100%;
  display: inline-block;
}

.dropdown-menu {
  padding: 0;
  min-width: 15rem;
}

.dropdown-menu-right {
  text-align: left;
  box-shadow: 0 0 20px -5px #000;
  border: none;
}

.dropdown-divider {
  margin: 0;
}

.dropdown-item {
  padding: 15px 20px;
  border-radius: inherit;
}

li.dropdown button a {
  color: #3d42f3;
  display: block;
}

.back-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  opacity: 0.8;
  transition: 0.1s all ease-in;
}

.back-button:hover, .back-button:active {
  opacity: 1;
}

.back-button *, .back-button:hover * {
  color: #fff;
}

.search-overlay {
position: fixed;
background-image: linear-gradient(to bottom, rgba(0,0,0,0.9), rgba(0,0,0,0.6));
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 2000;
}

.search-close-btn {
color: #fff;
text-decoration: none;
position: absolute;
top: 25px;
right: 25px;
}

.navbar-search-btn {
color: rgba(255,255,255,0.8);
transition: 0.1s color ease-in;
text-decoration: none;
}
.navbar-search-btn:hover, .navbar-search-btn:focus, .navbar-search-btn:active {
color: rgba(255,255,255,1);
}

.search-container {
position: absolute;
top: 100px;
left: 6rem;
right: 6rem;
padding: 50px;
}

.search-helper {
color: #ddd;
}

.navbar-search {
-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', Sans-Serif;
  font-weight: bold;
  font-size: 6rem;
  background: none;
  color: white;
  box-shadow: none !important;
  border: 0;
  padding: 0;
  height: 8rem;
}

.navbar-search::placeholder {
color: #888;
}

.navbar-search:focus {
color: #fff;
background: transparent;
outline:0;
}

.search-activate-btn {
background: #1329a2;
box-shadow: none;
outline: none;
border:none;
color: #fff;
min-width:115px;
transition: 0.1s all ease-in;
}

.search-activate-btn:hover {
background: #3d42f3;
outline: none;
border:none;
color: #fff;
box-shadow: 0px 7px 10px -8px #000;
}

.part-information .nav-tabs, .mbr-information .nav-tabs {
  border: none;
}

.part-information .nav-tabs li.nav-item > a, .mbr-information .nav-tabs li.nav-item > a {
  border-width: 1px 1px 1px 1px;
  border-color: #eee;
  border-bottom-color: #ccc;
  background: #fff;
  cursor: pointer;
  opacity: 0.5;
}

.part-information .nav-tabs li.nav-item > a.active, .mbr-information .nav-tabs li.nav-item > a.active {
  border-color: #ccc;
  border-bottom-color: #fff;
  opacity: 1;
  z-index: 1;
}

.part-information .nav-tabs li.nav-item > a:hover, .mbr-information .nav-tabs li.nav-item > a:hover {
  border-radius: .25rem .25rem 0 0;
  border-width: 1px 1px 1px 1px;
  border-bottom-color: #ccc;
  opacity: 0.8;
}


.sidebar-nav {
  margin-left: 100px;
}

.logo-sidebar {
  position: relative;
    font-size: 18pt;
    top: 0px;
    left: 0px;
    width: 200px;
    text-align: center;
    color: #fff !important;
    text-decoration: none !important;
    opacity: 0.8;
    background: transparent;
    transition: 0.15s all;
}

.logo-sidebar:hover {
  opacity: 1.0;
}