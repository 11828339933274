@import '../../../styles/variables.scss';

.items-list {
  width: 100%;
  height: calc(100vh - 81px);
  overflow-y: scroll;

  .loading {
    display: flex;
    align-items: center;
    height: calc(100vh - 81px);
    justify-content: center;
  }

  .btn.item-link {
    border: none !important;
    width: 100%;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 26px;
    border-radius: 0 !important;
    padding: 0px 5px 0px 5px !important;
    color: #2f363d !important;
    outline: 0 !important;
    box-shadow: none !important;
    transition: none;

    &:hover {
      background-color: #EEF0F0 !important;
    }

    &.active {
      background-color: #AAE1DB !important;
      color: #5B6871 !important;
    }

    &.active:hover {
      background-color: #007bff !important;
    }
  }
}
