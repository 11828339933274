@import '../../common/ItemsList/style.scss';

.items-list-container {
  width: 180px;
  max-width: 180px;
  padding-right: 0;

  .item-search-box {
    width: 100%;
    height: 36px;
    display: flex;
    padding: 6px 7px;
    position: relative;

    i {
      position: absolute;
      top: 9px;
      left: 12px;
      color: #5B6871;
    }

    input {
      width: 100%;
      padding: 2px 2px 1px 4px;
      outline: none;
      font-size: 11px;
      color: #5B6871;
      background-color: #ffffff;
      border: 1px solid #5B6871;
      border-radius: 0;
      text-align: center;
    }

    .load-more-balls {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .items-no-records {
    text-align: center;
    font-size: 14px;
    padding-top: 40px;
  }
}

.assessment-container {
  padding-left: 0;

  .parts-header,
  .detailed-assessment-info .detailed-assessment-info-header {
    font-weight: 600;
    max-height: 36px;
    min-height: 36px;
    display: flex;
    align-items: center;
    color: #5B6871;
    font-size: 14px;
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .parts-list {
    padding-right: 0;
    overflow-x: overlay;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    height: calc(100vh - 45px);

    .parts {
      overflow-y: scroll;
      overflow-x: hidden;

      .table-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        color: #2f363d;
      }

      .form-check-input {
        position: relative;
        margin-left: auto;
        margin-top: 0 !important;
      }

      .part-row-empty {
        min-height: 20px;
        max-height: 20px;
      }

      .part-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;

        &:nth-of-type(even) {
          background-color: rgba(90, 103, 112, 0.1);
        }
      }

      .part {
        width: 100%;

        &.banded {
          &:nth-of-type(even) {
            background-color: rgba(90, 103, 112, 0.1);
          }
        }

        &:hover {
          background-color: rgba(85, 195, 184, 0.1) !important;
        }

        padding-bottom: 2px;
        padding-top: 2px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        color: #2f363d;

        .invalid-row>i {
          color: #F5E209;
        }

        .input-group-text {
          border-color: #ced4da !important;
          border-radius: 0;
          font-size: 11px;
          max-height: 26px;
        }

        .row-buttons {
          button {
            margin-left: 5px;
            line-height: 26px;
            font-size: 12px;
            max-height: 26px;
            padding: 2px 8px !important;
          }
        }

        input,
        .form-control {
          max-height: 26px !important;
        }

        input[type='number'] {
          text-align: right;
          padding-right: 5px;
        }

        div,
        div>span {
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        >div {
          display: flex;
          align-items: center;
        }

        .form-group {
          width: 100%;
        }

        .full-width-dropdown {
          width: 100%;
        }

        button {
          font-size: 12px;
        }

        &[disabled] {
          color: #cccccc;

          input[type='checkbox']::before {
            background: rgb(204, 204, 204);
          }

          input[type='checkbox'] {
            border: 2px solid rgb(204, 204, 204);
          }
        }

        .m-checkbox__input:disabled {
          border-color: grey;
        }

        .part-row[disabled] {
          .checkbox {
            position: relative;
            cursor: default;
          }
        }

        .checkbox-in-table {
          .checkbox {
            position: relative;
            cursor: pointer;
          }

          .checkbox>input {
            height: 14px;
            width: 14px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border: 1px solid #5b6871;
            outline: none;
            transition-duration: 0.3s;
            background-color: #ffffff;
            cursor: pointer;
          }

          .checkbox>input:checked {
            border: 1px solid #007bff;
            background-color: #007bff;
          }

          .checkbox>input[disabled],
          .checkbox>input[disabled]:checked {
            opacity: 0.5;
            cursor: default;
          }

          .checkbox>input:checked+span::before {
            content: 'L';
            display: block;
            text-align: center;
            color: #ffffff;
            position: absolute;
            left: 4px;
            top: -1px;
            font-weight: 600;
            font-size: 11px;
            transform: scale(-1, 1) rotate(-45deg);
          }
        }

        .checkbox-in-table {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .detailed-assessment-info {
    .assessment-id {
      margin-left: auto;
      padding-right: 30px;
      text-align: right;
    }

    .assessment-info {
      width: 390px;
      height: calc(100vh - 81px);
      display: flex;
      flex-direction: column;
      position: relative;
      padding-left: 0;
      padding-right: 0;
      font-size: 11px;
      background-color: transparent;
      border-left: 1px solid #F8F8F8;

      .assessment-main-info {
        padding: 5px 0 10px 0;
        background-color: transparent;

        .row {
          align-items: center;
          min-height: 24px;
        }
      }

      .field-name {
        text-align: right;
        font-weight: 600;
        width: 140px;
        padding-right: 0;
        padding-left: 30px;
      }

      .field-value {
        overflow: hidden;
        width: 275px;
        padding: 0 35px 0 20px;
        word-break: break-all;
      }

      .payable-value {
        width: 140px;
        margin: 0 20px;
        padding: 2px 20px;
        font-size: 12px;
        text-align: center;
        background-color: #5b6871;
        color: #fff;
      }

      .suppliers {
        width: 100%;
        padding-right: 25px;
        padding-left: 20px;
        line-height: 25px;
      }

      .bottom-bar {
        height: 45px;
        position: absolute;
        bottom: 0;
        width: 100%;
        margin-left: 0;
        background-color: #CCD0D3;

        .info {
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #2d2d2d;
          z-index: 1000;
          background-color: #ffffff;
        }

        .actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0 15px 0 0;

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: 0 !important;
            background-color: #CCD0D3;
            color: #fff !important;
            border: 0 !important;
            width: 95px;
            cursor: pointer;

            &.btn-primary {
              background-color: #007bff !important;

              &:hover {
                background-color: #3d42f3 !important;
              }
            }

            &.btn-secondary {
              background-color: #CCD0D3 !important;
              color: #2f363d !important;

              i {
                color: #2f363d !important;
              }

              &:focus,
              &:active {
                background-color: #CCD0D3 !important;
              }

              &:hover {
                background-color: #bec2c6 !important;
              }
            }
          }

          i {
            color: #fff;
          }
        }
      }
    }
  }

  .btn-secondary {
    margin: 0 5px;
    border: none !important;
    outline: none;
    margin-left: 5px;
    line-height: 26px;
    font-size: 12px;
    max-height: 26px;
    padding: 2px 8px !important;
  }
}

.misc-info {
  height: 100%;
  background-color: #fafafa;

  .nav-tabs {
    height: 36px;
    background-color: #e9edf2;
    border: none;
  }

  .nav-item {
    height: 36px;
    width: 125px;
    cursor: pointer;
    color: #0567c9;
    text-align: center;

    .nav-link {
      color: #4b4c4e;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border: none;
      border-radius: 0;

      &.active {
        color: #0567c9;
        background-color: #e9edf2;
      }

      &:hover {
        background-color: #d8dfe9;
      }
    }
  }
}

.form-check-label {
  margin: .25em 2em;
}


.filename-preview {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100px;
  margin-top: 15px;
  width: auto;
  
  .filename {
    position: relative;
    .icon {
      position: absolute;
      left: -25%;
      top:-50%;
      opacity: 0.1;
    }
    font-size: 1.5em;
  }
  .actions {
    button {
      font-size: 1em;
      padding: 3px 5px;
      background-color: transparent !important;
      border: none !important;
      * {
        color: red;
      }
  
      &:hover {
        * {
          color: lighten(red, 25%);
        }
      }
    }
  }
}

tr.disabled > td {
  opacity: 0.6;
}

div.part-confirmation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.file-dropzone {
  padding: 35px;
  border: 3px dashed #ccc;
  font-size: 1.25em;
  line-height: 1em;
  margin-top: 15px;
  min-height: 150px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  p {
    margin: 0;
    text-align: center;
  }
}

.invoice-to-table, .deliver-to-table {
  thead {
    tr {
      th {
        h3 {
          font-weight: bold;
          font-size: 1.5em !important;
        }
      }
    }
  }
}

.order-parts {
  margin-top: 3em;
  thead {
    tr {
      th {
        border: none;
      }
    }
  }
}

.part-details {
  position: relative;
  height:100%;
  .part-information {
    height:100%;
    .action-buttons { 
      position: absolute;
      bottom: 2em;
      right: 2em;

      button {
        font-size: 12pt;
        padding: 1em 2em;
        border-radius: 10px;
      }
    }
    .table td, .table th {
      padding: 0;
      border: 0;
      vertical-align: middle;
    }
    .form-check-inline {
      margin:0;
    }
  }
}