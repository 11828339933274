.images-modal-footer {
  justify-content: space-between;
  flex-wrap: nowrap;

  .images-count {
    min-width: 100px;
    font-size: 18px;
    color: #2f363d;
    font-weight: 600;
    margin-right: auto;

    .img-loading-err-count {
      color: #fc6055;
    }
  }

  .loading-error {
    font-size: 14px;
    padding: 0 50px;
    color: #fc6055;
    font-weight: 600;
  }
}
