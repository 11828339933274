.load-more-balls.small {
  transform: scale(0.5);

  .loader {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .loader-inner div {
    background-color: #007bff;
  }

  &.white-balls .loader-inner div {
    background-color: #ffffff;
  }
  &.part-button-loader {
    .loader {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
