@import '~bootstrap/dist/css/bootstrap.min.css';
@import './variables.scss';
@import './Animations.scss';
@import './Inputs.scss';
@import './Toasts.scss';
@import './Modal.scss';

body {
  background: $mainBackground;
  background-position: 1200px -200px;
  background-repeat: no-repeat;
  background-size: 50%;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  background-color: #f6f6f6;
  height: 100vh;
}

//

*::-webkit-scrollbar-track {
  background-color: #F8F8F8;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #F8F8F8;
}

*::-webkit-scrollbar-thumb {
  background-color: #CCD0D3;
}

//

*::selection {
  background: #fc6055;
  color: #ffffff;
}

*::-moz-selection {
  background: #fc6055;
  color: #ffffff;
}

//


div.admin-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #3d42f3;
}

div.admin-sidebar {
  background: transparent;
  height: 100vh;
  max-height: 100vh;
  width: auto;
  min-width: 15vw;
  z-index: 3;
  display: flex;
  align-items: center;
  position: relative;
}

div.main-has-sidebar {
  width: 90vw;
  max-height:98vh;
  box-shadow: -3px 0 10px -5px #000;
  border-radius:15px 0 0 15px;
  overflow-y: hidden;
  background: #f9fafe;
}

.sidebar-nav {
  padding-left:0;
}

.sidebar-menu-listgroup {
  padding: 0;
  margin-top: -24px;
  width: 18vw;
  max-width: 250px;
  min-width: 200px;
}

.sidebar-menu-listgroup .list-group-item, .sidebar-menu-listgroup .list-group-item a {
  background-color: transparent;
  border: none;
  border-left: 3px solid transparent;
  color: #fff;
  opacity: 1;
  transition: 0.1s all ease-in;
  text-align: left;
  padding: .75em .5em .75em 0;
  transition: 0.1s all ease-in;
}
.sidebar-menu-listgroup .list-group-item {
  padding: .5em .5em .5em 0;
  transition: 0.1s all ease-in;
  max-width: 90%;
}

.sidebar-menu-listgroup .list-group-item:hover, .sidebar-menu-listgroup .list-group-item:hover a {
  opacity: 1;
}

.sidebar-menu-listgroup .list-group-item a {
  min-height: 60px;
  display: flex;
  align-items: center;
}

.sidebar-menu-listgroup .list-group-item:hover {
  background: #fff;
  border-radius: 0 10px 10px 0;
}

.sidebar-menu-listgroup .list-group-item:hover *:not(.badge) {
  color: #3d42f3;
}

li.list-group-item {
  border-left: 3px solid transparent;
}

li.active.list-group-item {
  opacity: 1;
  overflow: visible;
}

li.list-group-item > a {
  text-decoration: none !important;
  display: block;
  width: 100%;
  transition: 0.15s all ease-in;
  
}

li.active.list-group-item {
  background: rgba(255,255,255, 0.3);
  width: 100%;
  border-radius: 0 10px 10px 0;
  border-left: 3px solid white;
}

.sidebar-menu-listgroup .list-group-item.indent {
  padding-left: 2em;
}

.sidebar-menu-listgroup > .list-group-item p {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-left: 10px;
}

.sidebar-menu-listgroup > .list-group-item i {
  min-width: 35px;
  margin-left: 25px;
  text-align: center;
}

.sidebar-menu-listgroup > .list-group-item > div {
  padding: .5em 0;
}

.sidebar-menu-listgroup a.indent, .sidebar-menu-listgroup a.indent * {
    padding: 0 !important;
    font-size: 10pt !important;
    min-width: 0;
}

.sidebar-menu-listgroup a.indent {
  padding: .5em .5em .5em 2em !important;
  min-height: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.sidebar-menu-listgroup a.indent.sub i {
  position: absolute;
  right: 5px;
  bottom: 7px;
  visibility: hidden;
}

.sidebar-menu-listgroup a.indent.active.sub i {
  visibility: visible;
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.flex {
    display: flex;

    &.center {
      align-items: center;
      justify-content: center;
    }
  }
}



div.table-container {
  background: #fff;
  border-radius: 15px;
  margin-top: 75px;
  min-height: 350px;
  box-shadow: 0px 0px 25px -12px black;
  padding: 1px;
}

div.wide-container {
  margin: 0;
  max-width: 80vw;

}

div.table-container.sidebar {
  padding:0 !important;
}


div.container {
  max-width: 80vw;
}

div.container.padded-top {
  padding-top:40px;
}

div.container.padded-top.half {
  padding-top:25px;
}

div.container.padded-container {
padding: 40px 45px;
}


div.unmatched-container {
background: #fff;
max-width: 62vw;
width: 62vw;
border-radius: 15px;
margin: 0;
margin-top: 50px;
min-height: 350px;
left: 50px;
box-shadow: 0px 0px 25px -12px black;
padding: 1px;
}

.unmatched-container td {
padding-top: 5px;
padding-bottom: 5px;
}


.display-block {
  display: block;
}

.floating-controls {
  padding: 25px;
  position: fixed;
  background: #fff;
  top: 1vh;
  right: 0;
  width: 75vw;
  min-width: 450px;
  max-width: 1200px;
  border-radius: 15px 0 0 15px;
  box-shadow: -9px 0 22px -20px #333;
  z-index: 1050;
  height: 98vh;
  transition: 0.15s all ease-in;
  transform: translateX(100%);
}


.floating-controls.supplier {
  padding: 25px;
  position: fixed;
  background: #fff;
  top: 1vh;
  right: 0;
  width: 75vw;
  min-width: 450px;
  max-width: 1400px;
  border-radius: 15px 0 0 15px;
  box-shadow: -9px 0 22px -20px #333;
  z-index: 1100;
  height: 98vh;
  transform: translateX(100%);
}

.floating-controls.auto-height {
  padding: 0;
  position: fixed;
  background: #fff;
  top: auto;
  right: 0;
  width: calc(85vw /2);
  min-width: 450px;
  max-width: 750px;
  border-radius: 15px 0 0 15px;
  box-shadow: -9px 0 22px -20px #333;
  z-index: 1050;
  height: auto !important;
  transition: 0.15s all ease-in;
  transform: translateX(750px);
}

.floating-controls.open {
  transform: translateX(0);
}

.floating-close-btn {
  position: absolute;
  top: 25px;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 2.5em;
  cursor: pointer;
  z-index: 1000;
  color: #222;
  padding:1em 1em !important;
}


div.filled-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  margin-bottom: 0;
  background: #f9fafe;
  min-height: 98vh;
  border-radius: 15px 0 0 15px;
  margin-top: 0;
  position: relative;
  display: flex;
  align-items: flex-start;

}

div.filled-container.loaded {
  transition: min-height 0.15s ease-in;
}

div.filled-container.open .container-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,33,111,.35);
  z-index: 1060;
  border-radius: 15px 0 0 15px;
  transition: 0.15s opacity ease-in;
  pointer-events: none;
}

div.filled-container.open:hover .container-overlay {
  opacity: 0;
}

table.spaced-table *, table * {
  font-size: 10pt !important;
}

.bold {
  font-weight: bold;
}

.spaced-table {
  font-family: "Open Sans", sans-serif;
  border-collapse: collapse;
  border-spacing: 0 5px;
  border-collapse: separate;
  margin: 0;
  width:100%;
  padding: 5px 15px 5px 10px;
  min-width: 950px;
  font-size: 10pt;
}

.spaced-table td, .spaced-table th {
  border: 0;
  background: #fff;
  text-align: left;
}

.spaced-table thead tr, .workprovider-table thead tr {
  position: sticky; 
  top: 0px;
  z-index: 1000;
}

.spaced-table thead tr th, .workprovider-table thead tr th {
  margin: 0;
  border: 0;
  padding: .5em .5em;
  align-items: flex-start;
  position: sticky; 
  top: 0px;
  z-index: 100;
  background: #f9fafe;
}

.workprovider-table thead tr th {
  background: #fff;
}

.spaced-table thead tr th::before, .workprovider-table thead tr th::before {
  box-shadow: none;
  transition: 0.2s all ease-in;
  content: "";
  width: 100%;
  position: absolute;
  height: 5px;
  left: 0;
  bottom: 0;
}

.spaced-table.hoverable thead tr.active th::before, .spaced-table.hoverable thead tr:hover th::before, .spaced-table.hoverable thead tr.scrolling th::before,
.workprovider-table thead tr.scrolling th::before {
  box-shadow: 2px 3px 4px 0px rgba(0,0,0,0.14);
}

.spaced-table.hoverable thead tr.no-hover:hover th::before {
  box-shadow: none;
}

.workprovider-table td:first-child {
  padding-left: .5em;
}

.spaced-table td:first-child, .spaced-table th:first-child {
  padding-left: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.spaced-table td:first-child {
  padding-left: 0;
}



.spaced-table td:last-child, .spaced-table th:last-child {
  padding-right: 15px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.spaced-table input, .spaced-table .input-group-text {
  max-height: 30px;
  padding: 5px 10px;
}

.spaced-table .disabled .pricing-input {
  opacity: 0.5;
}

.spaced-table-header {
  font-family: urbane, sans-serif;
  border-collapse: collapse;
}

.spaced-table thead th {
  text-align: left;
  vertical-align: top;
  padding: 20px;
  font-family: urbane, sans-serif;
}

.spaced-table thead th.no-padding {
  padding: 0 !important;
}

.spaced-table tbody td {
  padding: 0 .5em 0 .75em;
  position: relative;
  font-size: 11pt;
  line-height: 1.1em;
  max-width: 200px;
  overflow: hidden;
  cursor: auto;

}

.spaced-table tbody td .truncated {
  width: 1000px;
  min-width: 0;
  overflow: hidden;
}

.spaced-table tbody td .truncated::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 70%, rgba(255,255,255,1) 100%);
}

.spaced-table .flex-td {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spaced-table .pricing-input {
  margin-right: 1em;
  max-width: 100%;
}

.table-switch-spinner {
  position: relative;
  right: 1.5em;
  display: inline-flex !important;
  align-items: center;
}

.spaced-table .empty-cell {
  height: 4px;
  width: 90%;
  margin: 0 auto;
  background: #f9f9f9;
}

.spaced-table .controlled-header .title {
  padding: 0px 3px;
}

.spaced-table .controlled-header .control {
  max-height: 38px;
  overflow: hidden;
  transition: 0.15s all ease-in;
  padding: 5px 3px;
}

.spaced-table thead tr.active .controlled-header .control, .spaced-table thead tr:hover .controlled-header .control {
  overflow: visible;
  max-height: 1000px;
}

.spaced-table .controlled-header .control.empty {
  height: 4px;
  width: 100%;
  margin: 1em auto;
  background: #f2f2f2;
  padding: 0;
}


.header-search .input-group-prepend {
  padding: .25em .5em;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  border-width: 1px 1px 1px 1px;
  z-index: 100;
  border-right-color: #fff;
}

.header-search input {
  border-width: 1px;
  padding-left: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 10pt;
  border-radius: 5px 5px 5px 5px !important;
  padding-left: 30px;
  margin-left: -30px;
  z-index: 50;
}

.header-search input::placeholder { /* Firefox, Chrome, Opera */ 
  color: #ccc; 
} 

.header-search input:-ms-input-placeholder { /* Internet Explorer 10-11 */ 
  color: #ccc; 
} 

.header-search input::-ms-input-placeholder { /* Microsoft Edge */ 
  color: #ccc; 
} 

.header-search i {
  color:#ccc;
}


button.list-action-btn, button.list-action-btn:hover, button.list-action-btn:active, button.list-action-btn:focus {
  background: transparent !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  transform: none;
  transition: 0.15s all ease-in;
  opacity: 0.6;
  line-height: 1em;
  color: #1c21ce !important;
}

button.list-action-btn:hover, button.list-action-btn:active, button.list-action-btn:focus {
  opacity: 1;
}

button.list-action-btn.disabled, button.list-action-btn.disabled:hover, button.list-action-btn.disabled:active, button.list-action-btn.disabled:focus {
  background: transparent;
  border-color: transparent;
  color: #aaa;
  transform: none;
  outline:none;
  box-shadow: none;
}

table.last-vehicle-table tr td {
  padding: .5em;
}


.assessment-row, .mbr-row {
  background: transparent;
}

.assessment-row td,  .mbr-row td {
  transition: 0.15s all ease-in;
  opacity: 0.9;
}

.assessment-row td.no-fade {
  opacity: 1;
}

.assessment-row td,  .mbr-row:hover td {
  opacity: 1;
  box-shadow: 0 0 24px -20px rgba(0,0,0,.5);
}

.fill-container {
  width:100%;
}

@function inputFrameColor($color, $amount) {
  @return lighten($color, $amount);
}

@mixin formBorder($color, $amount: 70%) {
  border-color: inputFrameColor($color, $amount);
}

.form-control {
  border-radius: 0;
  width: 100%;
  box-shadow: none !important;
  padding: 3px;
  border: 1px solid hsl(0, 0%, 80%);
  font-size: 1em;

  &:focus {
    border-color: #007bff;
  }

  &:disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
  }
}

.input-group {
  background: white;

  .input-group-text {
    background: transparent;
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
    max-height: 26px;
    @include formBorder($secondary);
  }

  &.disabled {
    .input-group-prepend {
      background: #e9ecef !important;
    }
  }
}

/**
* badge
*/
.badge {
  display: inline-block;
  padding: 3px 5px;
  min-width: 37px;
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-primary {
  background-color: #007bff;
}

.badge-secondary {
  background-color: #5b6871;
}

.badge-danger {
  background-color: #dc3545;
}

/**
* button
*/
button.btn {
  border-radius: 0;
  border: none;
  font-weight: 600;
  padding: 4px 21px;
  margin: 0;
  font-size: 11px;
  outline: 0;
  box-shadow: none;

  &.btn,
  &.btn.btn-primary,
  &.btn.btn-secondary,
  &.btn.btn-danger {

    &.active:focus,
    &.btn,
    &:active,
    &:focus,
    &:active:focus {
      box-shadow: none;
      border: none;
    }
  }

  &.btn-primary,
  &.btn-primary:not(:disabled):not(.disabled) {
    background-color: #007bff;
    color: #ffffff;

    &:focus,
    &:active {
      background-color: #007bff;
    }

    &:hover {
      background-color: #3d42f3;
    }

    &:disabled {
      background-color: #007bff;
      color: #ffffff;
    }
  }

  &.btn-secondary,
  &.btn-secondary:not(:disabled):not(.disabled) {
    background-color: #ccd0d3;
    color: #2f363d;

    &:focus,
    &:active {
      background-color: #ccd0d3;
    }

    &:hover {
      background-color: #bec2c6;
    }
  }

  &.btn-danger,
  &.btn-danger:focus,
  &.btn-danger:active {
    background-color: #dc3545;
    color: #ffffff;

    &:hover {
      background-color: #d02536;
    }
  }

  &:disabled {
    pointer-events: none;
  }
}

// swal2
.swal2-styled.swal2-confirm {
  background-color: #007bff;
  border-radius: 0;
  box-shadow: none;
}

.swal2-icon.swal2-warning {
  color: #000000;
  border-color: #F5E209;
}

label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.field-limit-indicator {
  color: #ccc;
  font-size: 0.85em;
  text-align: right;
  float: right;
}

.load-more {
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.loading-text {
  font-size: 14px;
  font-weight: 600;
  color: #707070;
}

// risky approach..
.cstm-input {
  >div:nth-of-type(1)>div:nth-child(1) {
    padding: 0 6px;

    >div {
      margin: 1px;
      height: 20px;
      min-height: 38px !important;
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        padding: 0;
      }
    }

    &:hover {
      cursor: text;
    }
  }

  >div div:nth-child(2)>div {
    padding: 2px 2px 2px 0px;
  }
}

.card input.form-control:focus {
  box-shadow: none;
  border: 1px solid #cccccc;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.panel-container {
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .close {
    margin: 5px;
    color: #fc6055;
    float: left;
    cursor: pointer;
  }

  .heading {
    float: right;
    display: flex;
    align-items: center;
    margin: 10px;
    font-size: 16px;
    font-weight: 600;
  }
}

//

.loader {
  .loader-inner.ball-pulse-sync div {
    background-color: #007bff;
  }
}

.button-loader {
  .loader {
    margin: 5px 0 0 0;
  }
}

//

.full-page-message {
  width: 100%;
  display: flex;
  height: calc(100vh - 75px);
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
}

.no-margin {
  margin: 0;
}

.po-title {
  font-size: 1.25em;
}

//

.swal2-modal.swal2-popup {
  border-radius: 0;

  .swal2-styled.swal2-confirm,
  .swal2-styled.swal2-cancel {
    border-radius: 0;
    box-shadow: none;
  }

  .swal2-styled.swal2-confirm {
    background-color: #007bff;
  }

  .swal2-styled.swal2-cancel {
    background-color: #5b6871;
  }

  .swal2-icon.swal2-warning {
    color: #000000;
    border-color: #f5e209;
  }
}
