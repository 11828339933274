@import './global.scss';

.empty-queue {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}

.main {
  overflow: hidden;

  .app-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    margin-top: 45px;

    .app-sidebar {
      position: relative;
      height: 100%;
      min-width: 172px;
      max-width: 172px;
    }

    .app-main {
      position: relative;
      width: calc(100vw - 172px);
      padding: 0;
    }

    .empty-queue {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 80px);
      width: 100vw;

      h1 {
        font-size: 2.5em;
        color: #aaa;
      }
    }
  }
}
