@import 'global';

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #051e4d;

  &::before {
    content: '';
    background-image: url('../images/splash.jpg');
    background-color: transparent;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.1;
    z-index: 1;
  }
 
}

.login-container {
  position: relative;
  width: 400px;
  background: #fff;
  box-shadow: 0 0 15px -5px #444;
  z-index: 10;
  border-radius: 12px;
  .login-header {
    width: 100%;
    height: 120px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    background-color: #CCD0D3;
    background-color: #CCD0D3;
    border-radius: 10px 10px 0 0;
    position: relative;
    padding-top: 40px;
    text-align: center;
    .login-icon {
      background-image: url('../images/icon.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 100%;
      height: 75px;
      border-radius: 10px;
      text-align: center;
      background-size: contain;
      position: absolute;
      top: -35%;
      left: 0;
      right: 0;
    }
    h1 {
      font-weight: bold;
      font-size: 1.5em;
      margin: 0;
    }
    h2 {
      margin: 0;
      font-size: 1.25em;
    }
  }

  .loader {
    height: 40px;
    margin: 5px 0 15px 0;
  }

  .form-groups {
    padding: 35px;

    .form-group {
      margin-bottom: 0;
      background: transparent;
      &:first-of-type {
        margin-bottom: 25px !important;
      }

      .input-group-prepend {
        position: absolute;
        height: 36px;
        padding: 0 8px;
        background: #0558f9;
        color: #fff;
        z-index: 4;
        display: flex;
        align-items: center;
        border-radius: 5px 0 0 5px
      }

      input {
        height: 36px;
        padding: 0 10px 0 50px;
        font-size: 14px;
        border-color: #CCD0D3;
        box-shadow: none;
        border-radius: 5px;

        &::placeholder {
          color: #CCD0D3;
        }

        &:focus {
          border-color: #007bff;
        }
      }
    }
  }

  .text-danger {
    margin: 0 35px 24px 35px;
    font-size: 12px;
    font-weight: 600;
  }

  .login-button,
  .login-button:focus,
  .login-button:active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    font-size: 20px;
    font-weight: 400 !important;
    background: #0558f9 !important;
    color: #fff !important;
    border: none;
    background-color: #CCD0D3;
    border-radius: 0 0 10px 10px;

    &:disabled {
      opacity: 1;
      background: #CCD0D3 !important;
      color: #5B6871 !important;
    }
  }
}
