.suppliers-container {
  display: flex;
}

.col.with-button {
  display: flex;
  flex-direction: row;

  >div {
    flex: 1;
  }


  * {
    line-height: 1em;
  }

  button {
    font-size: 1em;
  }
}

.quote-grade {
  width: 100%;
  max-height: 26px;
  height: 26px;

  >div:nth-of-type(1)>div:nth-child(1)>div {
    max-height: 26px !important;
    height: 26px;
  }
}