@import 'global';

$primary-color: $primary;

@import 'loaders.css/src/animations/ball-pulse-sync.scss';

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

.loading-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

img.logo {
  &.loading {
    max-height: 100px;
    min-height: 100px;
  }
}

.loader {
  margin-top: 35px;
  .loader-inner {
    text-align: center;
  }
}